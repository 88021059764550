<template>
  <main id="Basic">
    <CContainer>
      <CCard>
        <CCardHeader>
          <h5 class="mb-0">{{ $t('Navigation.Message/MailSetting') }}</h5>
        </CCardHeader>
        <CCardBody>
          <CTabs fade addTabsWrapperClasses="tab-info">
            <CTab title="系統通知" active>
            </CTab>
            <CTab title="供應商設定">
            </CTab>
          </CTabs>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCardBody>
      </CCard>
    </CContainer>
  </main>
</template>

<route>
{
  "meta": {
    "label": "寄送設定"
  }
}
</route>

<script>
import District from '@/assets/json/Districts.json'

export default {
  name: 'BasicSetting',
  layout: 'manage',
  components: {
  },
  data () {
    return {
      Accordion: 0,
      BasicData: {
        Info: {},
        Contact: {},
        Social: [],
        Location: []
      },
      Loading: false,
      Submit: false,
      NowChooseLanguage: 'default',
      ChooseImageType: '',
      ChooseImageIndex: false,
      ChooseImageItem: '',
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      Features: [],
      SocialOptions: {
        Facebook: 'cib-facebook',
        Line: 'cib-line',
        Twitter: 'cib-twitter',
        Linkedin: 'cib-linkedin',
        Github: 'cib-github',
        Instagram: 'cib-instagram',
        Youtube: 'cib-youtube',
        Behance: 'cib-behance',
        Discord: 'cib-discord'
      },
      FieldName: '',
      NowSelectLocation: {},
      NowSelectLocationIndex: false,
      LocationSearchFilter: {
        City: '',
        Area: ''
      },
      LocationPage: 0,
      LocationInfiniteId: +new Date(),
      LocationShowFilter: 'All',
      LocationFilterModel: false,
      LocationModel: false,
      LocationField: [
        { key: 'PostNum', label: this.$t('System/Basic.LocationField.PostNum') },
        { key: 'Name', label: this.$t('System/Basic.LocationField.Name') },
        { key: 'City', label: this.$t('System/Basic.LocationField.City') },
        { key: 'Address', label: this.$t('System/Basic.LocationField.Address') },
        { key: 'Tel', label: this.$t('System/Basic.LocationField.Tel') },
        { key: 'Status', label: this.$t('System/Basic.LocationField.Status') },
        { key: 'Action', label: '' }
      ],
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      },
      District
    }
  },
  computed: {
    Country () {
      return ['Taiwan']
    },
    City () {
      return this.District.map(Data => Data.name)
    },
    Area () {
      const ChooseCityData = this.District.filter(Data => Data.name === this.NowSelectLocation.City) || []
      if (ChooseCityData.length > 0) {
        return ChooseCityData[0].districts.map(Area => Area.name)
      } else {
        return []
      }
    },
    AreaByFilter () {
      const ChooseCityData = this.District.filter(Data => Data.name === this.LocationSearchFilter.City) || []
      if (ChooseCityData.length > 0) {
        return ChooseCityData[0].districts.map(Area => Area.name)
      } else {
        return []
      }
    },
    FilterSocialOptions () {
      const Result = {}
      const ExistData = this.BasicData.Social.map(Data => Data.Name)
      Object.keys(this.SocialOptions).forEach((SocialName) => {
        if (!ExistData.includes(SocialName)) {
          Result[SocialName] = this.SocialOptions[SocialName]
        }
      })
      return Result
    }
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.System.Basic
      const LoadData = this.Features.map(type => {
        return this.GetData(type)
      })
      return Promise.all(LoadData).then(() => {
          if (this.$store.state.user.permission.MutipleLocale) {
              const LangSchema = ['Info', 'Contact']
              LangSchema.forEach((schema) => {
                  if (!this.BasicData[schema].LanguageData) {
                      this.$set(this.BasicData[schema], 'LanguageData', {})
                  }
                  this.$store.state.user.permission.Locales.forEach((locale) => {
                      if (!this.BasicData[schema].LanguageData[locale]) {
                          this.$set(this.BasicData[schema].LanguageData, locale, {})
                      }
                  })
              })
              this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
          }
      }).catch((err) => {
        throw err
      })
    },
    GetData(type) {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/' + type,
        method: 'post'
      }).then(({data}) => {
        this.$set(this.BasicData, type, data)
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetLocationData($state) {
      const data = {}
      if (this.BasicData.Location.length > 0) {
        data.next = this.BasicData.Location[this.BasicData.Location.length - 1].PostNum
      }
      if (this.LocationSearchFilter.City !== '') {
        data.city = this.LocationSearchFilter.City
      }
      if (this.LocationSearchFilter.Area !== '') {
        data.area = this.LocationSearchFilter.Area
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/Location',
        method: 'post',
        data
      }).then(({data}) => {
        this.Loading = false
        if (data.length) {
          this.BasicData.Location.push(...data)
          if ($state) $state.loaded()
        } else {
          if ($state) $state.complete()
        }
        return true
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    ChangeLocationFilter(reset = false) {
      if (reset) {
        this.LocationSearchFilter = {
          City: '',
          Area: ''
        }
      }
      this.Loading = true
      this.LocationInfiniteId += 1
      this.LocationFilterModel = false
      this.BasicData.Location = []
      this.GetLocationData()
    },
    SetData(type) {
      const data = {
        [type]: this.BasicData[type]
      }
      if (data.Info && data.Info.Domain) {
        data.Info.Domain = data.Info.Domain.replace('https://', '').replace('http://', '').replace('/', '')
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/' + type,
        method: 'post',
        data
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    AddData(type) {
      if (!this.BasicData[type]) this.$set(this.BasicData, type, [])
      let Data
      switch (type) {
        case 'ServiceLocation':
          Data = {
            Name: '[' + this.$t('System/Themes.ServiceLocationTitle') + ']',
            Address: '',
            BusinessHours: '',
            TEL: '',
            FAX: '',
            GoogleMapURL: '',
            LanguageData: {}
          }
          this.$store.state.user.permission.Locales.forEach((locale) => {
            Data.LanguageData[locale] = {
              Name: '[' + this.$t('System/Themes.ServiceLocationTitle') + ']',
              Address: '',
              BusinessHours: '',
              TEL: '',
              FAX: '',
              GoogleMapURL: '',
            }
          })
          break
        case 'Social':
          Data = {
            Link: '',
            Name: 'Facebook'
          }
          break
      }
      this.BasicData[type].push(Data)
    },
    RemoveData(type, index) {
      this.BasicData[type].splice(index, 1)
    },
    OpenLocationModel(index = false) {
      if (index !== false) {
        this.NowSelectLocation = this.BasicData.Location[index]
        this.NowSelectLocationIndex = index
      } else {
        this.NowSelectLocation = {
          Country: 'Taiwan'
        }
      }
      this.LocationModel = true
    },
    CloseLocationModel() {
      this.NowSelectLocation = {
        Country: 'Taiwan'
      }
      this.NowSelectLocationIndex = null
      this.LocationModel = false
    },
    SetZip () {
      this.NowSelectLocation.PostNum = parseInt(this.District.filter(Data => Data.name === this.NowSelectLocation.City)[0].districts.filter(Area => Area.name === this.NowSelectLocation.Area)[0].zip)
    },
    SaveLocation() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/Location',
        method: 'post',
        data: {
          DocID: this.NowSelectLocation.DocID || null,
          Location: this.NowSelectLocation
        }
      }).then(() => {
        this.Loading = false
        this.LocationModel = false
        this.GetData('Location')
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    DeleteLocation(index) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/delete/Location',
        method: 'post',
        data: {
          DocID: this.BasicData.Location[index].DocID
        }
      }).then(() => {
        this.Loading = false
        this.LocationModel = false
        this.GetData('Location')
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/DeleteFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    //圖片處理
    OpenMediaStore(type = 'Carousel', multiple = true, index = false, item = 'Cover') {
      this.ChooseImageType = type
      this.ChooseImageItem = item
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    SetImages (type = 'ServiceLocation', index, item = '') {
      switch (type) {
        case 'ServiceLocation':
        case 'Info':
          this.SetSingleImage(type, index, item)
          break
        case 'NowSelectLocation':
          this.SetSingleImage2(type, item)
          break
      }
    },
    SetSingleImage(type, index, item) {
      if (this.$store.state.user.permission.StaticDomain) {
          if (this.NowChooseLanguage === 'default') {
              if (index) this.$set(this.BasicData[type][index], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
              else this.$set(this.BasicData[type], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
          } else {
              if (index) this.$set(this.BasicData[type][index].LanguageData[this.NowChooseLanguage], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
              else this.$set(this.BasicData[type].LanguageData[this.NowChooseLanguage], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
          }
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
            if (this.NowChooseLanguage === 'default') {
                if (index) this.$set(this.BasicData[type][index], item, URL)
                else this.$set(this.BasicData[type], item, URL)
            } else {
                if (index) this.$set(this.BasicData[type][index].LanguageData[this.NowChooseLanguage], item, URL)
                else this.$set(this.BasicData[type].LanguageData[this.NowChooseLanguage], item, URL)
            }
        })
      }
    },
    SetSingleImage2(type, item) {
      if (this.$store.state.user.permission.StaticDomain) {
        this.$set(this[type], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          this.$set(this[type], item, URL)
        })
      }
    }
  }
}
</script>

<style scoped>
.favicon {
  width: 64px;
  height: 64px;
}
.CarouselItem {
  min-height: initial;
}
</style>
